window.$ = window.jQuery = require("jquery")

require("infiniteslidev2")

import Typed from "typed.js";

$(document).ready(function(){
    $(".wrapper").on("click",".menu li a", function (event) {
        event.preventDefault();
        var id  = $(this).attr('href'),
            top = $(id).offset().top;
        $('body,html').animate({scrollTop: top}, 1000);
    });


    // menu mobile
    var toggleButton = $('.js--open-menu'),
        menuLi = $('.menu li a'),
        menuWrap = $('.header-menu');
    toggleButton.on('click', function () {
        $(this).toggleClass('active');
        menuWrap.toggleClass('active');
        $('body').toggleClass('overflow');
    });
    menuLi.on('click', function () {
        toggleButton.removeClass('active');
        menuWrap.removeClass('active');
        $('body').removeClass('overflow');
    });



    // typed
    var typed = new Typed('#typed', {
        stringsElement: '#typed-strings',
        typeSpeed: 100,
        startDelay: 1000,
        loop: true,
        loopCount: Infinity,
    });



    $('.slider-left').infiniteslide({
        'pauseonhover': false,
        'speed': 50,
        clone: 3,
    });

    $('.slider-right').infiniteslide({
        'pauseonhover': false,
        'speed': 50,
        'direction': 'right',
        clone: 3,
    });

});