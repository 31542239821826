<template>
  <div class="section section--fearless">
    <div class="container">

      <div class="fearless clearfix">
        <img src="@/assets/img/fearless/bg-blur.webp" class="section--fearless__bg" alt="">
        <img src="@/assets/img/fearless/block.svg" class="fearless-bg" alt="">
        <div class="fearless-content">
          <div class="fearless-title">
            Or are you so fearless
          </div>
          <div class="fearless-text">
            that are you ready to fight <br/>in my special duel?
          </div>
          <a href="" class="btn btn-primary">
            <img src="@/assets/img/logo-dark.svg" alt="">
          </a>
        </div>
        <img src="@/assets/img/fearless/1.webp" srcset="@/assets/img/fearless/1@2x.webp" class="fearless-img" alt="">
      </div>

    </div>
  </div>
</template>