<template>
  <div class="pug">
    <div class="pug-title" v-html="title"></div>
    <div class="pug-wallet">
      <div class="pug-wallet__row">
        Wallet address:
        <div class="pug-wallet__item metamask_wallet" v-html="wallet"></div>
      </div>
    </div>
    <div class="pug-calc">
      <div class="pug-calc__sum">
        Total price: {{ totalPrice }} ETH
      </div>
      <div class="pug-calc__form">
        <div class="number" :class="{limited: tokenToBuy >= 2}">
          <span class="number-btn minus" @click="minus">-</span>
          <input type="text" class="number-field" :value="tokenToBuy"/>
          <span class="number-btn plus" @click="plus">+</span>
        </div>
        <button type="submit" class="pug-calc__send" @click="buy" :disabled="mintDisabled">
          Mint
        </button>
      </div>
    </div>
  </div>

</template>


<script type="module">

import Contract from "@/service/contract"
import {mapActions} from "vuex";

export default {
  name: "CountdownView",
  props: {
    title: String,
    price: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      wallet: '',
      tokenToBuy: 1,
      maxTokenToBut: 2,
      mintDisabled: false,
    }
  },
  computed: {
    totalPrice() {
      return this.price * this.tokenToBuy
    }
  },
  async mounted() {
    // eslint-disable-next-line no-undef
    let wallets = await ethereum.request({ method: 'eth_requestAccounts' })
    this.wallet = wallets[0]
  },
  methods: {
    minus() {
      this.tokenToBuy--
      if (this.tokenToBuy <= 1) {
        this.tokenToBuy = 1
      }
      $(".number-field").change()
    },
    plus() {
      this.tokenToBuy++
      if (this.tokenToBuy >= 2) {
        this.tokenToBuy = 2
      }
      $(".number-field").change()
    },
    ...mapActions(['validateWallet']),
    async buy() {
      const w3 = new Web3()
      const contract = new Contract(w3.utils.toWei(String(this.totalPrice)))
      contract.buy().then(success => {
        if (success) {
          this.$router.push({path: "congrats"})
        }
      })
    }
  }
}
</script>

<style scoped>

</style>