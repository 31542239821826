import { createStore } from 'vuex'
import axios from "axios";

// eslint-disable-next-line no-unused-vars
const WHITE_LIST_API = process.env.VUE_APP_WHITELIST_API

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    getWhitelist: async () => {
      return await axios.get(WHITE_LIST_API).then(resp => {
        return resp.data.whitelist.map(item => item.toLowerCase())
      }).catch(err => console.error(err))
    }
  },
  modules: {
  }
})
