import { ethers } from "ethers"
import abi from "./abi.json"

class Contract {
    __payment = 0

    constructor(payment) {
        this.__payment = payment
    }

    async buy() {
        if (typeof window.ethereum == 'undefined') {
            alert("Please install MetaMask extension!")
            return false;
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        await provider.send("eth_requestAccounts", []);

        const contract = new ethers.Contract(
            process.env.VUE_APP_CONTRACT_ADDRESS,
            abi,
            provider.getSigner(),
        )

        try {
            const tx = await contract.mint({value: this.__payment})
            await tx.wait()
            return true
        } catch (error) {
            console.log(error);
            switch (error.code) {
                case "INSUFFICIENT_FUNDS":
                    alert("Please make sure you have enough funds in your wallet. Mint is not possible if there are not enough funds on the wallet.")
            }
            return false
        }
    }
}

export default Contract