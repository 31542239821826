<template>
  <div class="section section--about">
    <div class="container">
      <div class="about">
        <div class="title about-title">
          <div id="typed-strings">
            <p>
              I am <span>Norman</span>
            </p>
          </div>
          <span id="typed"></span>
          <!--                I am <span>Norman</span>-->
        </div>
        <p>And soon I will give you the most exciting game in the world. And all you need to start is an NFT Soul and
          some tokens. And die will be cast and will determine your opponent. You can put only tokens or tokens and a
          soul on the line. Win tokens and souls from your opponents. The bravest and boldest will be willing to play
          the Norman's Duel. And some will lose everything and others will gain everything.</p>
      </div>
    </div>
  </div>
</template>