<template>
  <div class="pug-form">
    <a :href="whiteListFormLink" class="pug-form__btn glitch-btn">
      <span class="glitch-text" data-glitch="Whitelist">Whitelist</span>
    </a>
    <div class="pug-form__instruction">
      Check if you have been approved <br/>for the whitelist
    </div>
    <div class="pug-form__label">
      Public address:
    </div>
    <div class="pug-form__item">
      <input type="text" v-model="wallet" class="pug-form__field" placeholder="0x000..." id="address-input">
      <button class="pug-form__copy">
        Generate and copy to clipboard
      </button>
    </div>
    <button class="pug-form__link glitch-text" data-glitch="Proof" @click="validateWallet">Proof</button>
  </div>

  <wallet-popup-component ref="walletPopup"></wallet-popup-component>
</template>

<script>
import { mapActions } from "vuex"
import WalletPopupComponent from "@/components/ntf_components/WalletPopupComponent";


export default {
  name: "PugFormComponent",
  components: {WalletPopupComponent},
  props: {
    whiteListFormLink: String
  },
  data() {
    return {
      wallet: ''
    }
  },
  methods: {
    ...mapActions(['getWhitelist']),
    async validateWallet() {
      if (this.wallet.length > 0) {
        let whitelist = await this.getWhitelist()

        if (whitelist.includes(this.wallet.toLowerCase())) {
          this.$refs.walletPopup.openSuccess(this.wallet)
        } else {
          this.$refs.walletPopup.openError(this.wallet)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>