<template>
  <div class="section section--token" id="token">
    <img src="@/assets/img/token/bg.webp" class="section--token__bg" alt="">
    <div class="container clearfix">

      <div class="token">
        <div class="token-title">
          I,Norman, give you a <span>Norman Token</span> (Nort)
        </div>
        <div class="note">
          that should soon be more expensive and better than dollar.
        </div>
        <img src="@/assets/img/token/man.webp" class="token-img" alt="">
        <div class="token-row">
          <div class="token-col">
            <div class="token-box">
              <p>Because only Nort can be obtained <br/>by anyone at any time in a fair duel.</p>
              <p> Since I will give you a Norman Market where any product or service you can sell or buy with a Nort.</p>
              <p> I'll only give you 100 billion tokens.</p>
              <p> And only 1 billion tokens anyone can <br/>buy at 0,01USDT and sell at any price.</p>
            </div>
          </div>
          <div class="token-col">
            <div class="token-count">
              <span>1 000 000 000</span> Nort
            </div>
            <div class="token-box">
              <p>The other 99 billion tokens can only <br/>be obtained by dueling according to <span>Norman's Code Duello,</span> or by keeping order at the Norman Market.</p>
              <p>Every brave will have enough <br/>money and fame.</p>
            </div>
          </div>
        </div>

        <router-link to="/wait" href="" class="btn btn-primary">Whitelist</router-link>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "TokenComponent",
  data() {
    return {
      whiteListFormLink: process.env.VUE_APP_NORT_WHITELIST_FORM_LINK
    }
  }
}
</script>