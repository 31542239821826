<template>
  <div>
    <header-component></header-component>
    <about-component></about-component>
    <svg aria-hidden="true" style="position: absolute; width: 0; height: 0; overflow: hidden;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <symbol id="catalog-mask" viewBox="0 0 32 32">
          <path d="M28.433 31.878h-24.99l-3.443-3.443v-24.992l3.443-3.443h24.99l3.443 3.443v24.992l-3.443 3.443zM3.541 31.641h24.793l3.303-3.304v-24.795l-3.303-3.304h-24.793l-3.303 3.304v24.795l3.303 3.304z"></path>
          <path d="M9.656 30.997h-5.847l-2.927-2.926v-24.263l2.927-2.926h3.038v0.237h-2.939l-2.788 2.788v24.066l2.788 2.787h5.749v0.237z"></path>
          <path d="M28.068 30.997h-5.852v-0.237h5.754l2.788-2.787v-24.066l-2.788-2.788h-3.151v-0.237h3.249l2.927 2.926v24.263l-2.927 2.926z"></path>
          <path d="M22.678 2.909l1.948-2.789h-17.377l1.948 2.789h13.481z"></path>
          <path d="M10.309 30.042h11.256l0.615 0.88h1.83l-1.334-1.909h-13.481l-1.333 1.909h1.83l0.615-0.88z"></path>
          <path d="M1.369 2.896l-0.674 0.674 0.281 0.281 0.674-0.674-0.281-0.281z"></path>
          <path d="M2.487 1.778l-0.674 0.674 0.281 0.281 0.674-0.674-0.281-0.281z"></path>
          <path d="M3.604 0.658l-0.674 0.674 0.281 0.281 0.674-0.674-0.281-0.281z"></path>
          <path d="M30.227 3.177l0.674 0.674 0.281-0.281-0.674-0.674-0.281 0.281z"></path>
          <path d="M29.106 2.062l0.674 0.674 0.281-0.281-0.674-0.674-0.281 0.281z"></path>
          <path d="M27.989 0.942l0.674 0.674 0.281-0.281-0.674-0.674-0.281 0.281z"></path>
          <path d="M30.508 28.985l0.674-0.674-0.281-0.281-0.674 0.674 0.281 0.281z"></path>
          <path d="M29.388 30.098l0.674-0.674-0.281-0.281-0.674 0.674 0.281 0.281z"></path>
          <path d="M28.271 31.219l0.674-0.674-0.281-0.281-0.674 0.674 0.281 0.281z"></path>
          <path d="M1.651 28.7l-0.674-0.674-0.281 0.281 0.674 0.674 0.281-0.281z"></path>
          <path d="M2.77 29.818l-0.674-0.674-0.281 0.281 0.674 0.674 0.281-0.281z"></path>
          <path d="M3.885 30.937l-0.674-0.674-0.281 0.281 0.674 0.674 0.281-0.281z"></path>
          <path d="M1.952 15.939l-0.952-0.808v1.617l0.952-0.809z"></path>
          <path d="M29.925 15.939l0.952 0.809v-1.617l-0.952 0.808z"></path>
        </symbol>
      </defs>
    </svg>
    <catalog-component></catalog-component>
    <today-component></today-component>
    <fearless-component></fearless-component>
    <token-component></token-component>
    <wait-component></wait-component>
    <market-component></market-component>
    <roadmap-component></roadmap-component>
    <contact-component></contact-component>
  </div>
</template>

<script>
// @ is an alias to /src
import TokenComponent from "@/components/home_sections/TokenComponent";
import MarketComponent from "@/components/home_sections/MarketComponent";
import HeaderComponent from "@/components/HeaderComponent";
import RoadmapComponent from "@/components/home_sections/RoadmapComponent";
import AboutComponent from "@/components/home_sections/AboutComponent";
import CatalogComponent from "@/components/home_sections/CatalogComponent";
import TodayComponent from "@/components/home_sections/TodayComponent";
import FearlessComponent from "@/components/home_sections/FearlessComponent";
import WaitComponent from "@/components/home_sections/WaitComponent";
import ContactComponent from "@/components/home_sections/ContactComponent";

export default {
  name: 'HomeView',
  components: {
    ContactComponent,
    WaitComponent,
    FearlessComponent,
    TodayComponent,
    CatalogComponent,
    AboutComponent,
    RoadmapComponent,
    HeaderComponent,
    MarketComponent,
    TokenComponent,
  }
}
</script>
