<template>
  <div class="section section--wait">
    <div class="container">
      <div class="title wait-title">
        CODE DUELLO
      </div>
      <div class="wait-img wait-img--home">
        <img src="@/assets/img/bg.webp" alt="">
      </div>
      <div class="wait-text">
        Wait and you'll get it soon
      </div>
    </div>
  </div>
</template>