<template>
  <div class="section section--market" id="market">
    <img src="@/assets/img/market/bg.webp" class="market-bg" alt="">
    <div class="container">
      <div class="title market-title">MARKET</div>
      <div class="market-subtitle">
        And there will be no <br class="br-mob"/>commissions or interest <br class="br-mob"/>on this market.
      </div>
      <div class="market-text">
        <p><b>And no one will own or profit <br class="br-mob"/>from this market.</b></p>
        <p>And anyone can get their share <br class="br-mob"/>of the 99 billion <br/>Norman Token if they <br class="br-mob"/>keep the Market in order <br/>and follow <br class="br-mob"/>reasonable rules.</p>
        <p>And anyone can buy anything and sell <br/> <br class="br-mob"/>anything as long as it doesn't hurt people.</p>
        <p>Wait and you'll get it soon.</p>
      </div>
      <img src="@/assets/img/market/1.webp" srcset="@/assets/img/market/1@2x.webp" class="market-img" alt="">
    </div>
  </div>
</template>