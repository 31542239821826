<template>
  <div class="pug pug--xl pug--text">
    <div class="pug--text__head">
      <div class="pug-title">Congratulations you're about to mint your first Duelist!</div>
      <div class="pug--text__icon">
        <img src="@/assets/img/icons/check.svg" alt="">
      </div>
      <div class="pug--text__subtitle">
        <p>Confirm the transaction in the MetaMask and check it on <a href="https://etherscan.io/">https://etherscan.io/</a></p>
      </div>
    </div>
    <div class="pug--text__list">
      <p>Use MetaMask Mobile App to see your NFT</p>

      <p>Go to the wallet in the app</p>
      <p>In the wallet, go to the ‘NFT’ tab</p>

      <p>If NFT was not automatically detected:</p>

      <ol>
        <li>Click on "Import NFTs"</li>
        <li>Copy & Paste the following data</li>
      </ol>
      <p></p>
      <p>Address: 0x000000000</p>
      <p>ID: you can find the ID of NFT following your Txn Hash on <a href="https://etherscan.io/">https://etherscan.io/</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CongratsView"
}
</script>

<style scoped>

</style>