import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ApesView from "@/views/ApesView";
import CountdownView from "@/views/subviews/CountdownView";
import ConnectWalletView from "@/views/subviews/ConnectWalletView";
import BuyView from "@/views/subviews/BuyView";
import CongratsView from "@/views/subviews/CongratsView";
import NinjaView from "@/views/NinjaView";
import CatsView from "@/views/CatsView";
import SoldOutView from "@/views/subviews/SoldOutView";
import moment from 'moment';
import WaitView from "@/views/WaitView";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/wait',
        name: 'wait',
        component: WaitView
    },
    {
        path: '/apes-mint',
        name: 'apes-mint',
        beforeEnter: () => {
            return {path: '/apes/wallet'}
        }
    },
    {
        path: '/apes',
        name: 'apes',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: ApesView,
        children: [
            {
                path: '',
                component: CountdownView,
                props: {
                    expireDate: process.env.VUE_APP_APES_EXPIRIED_DATE,
                    title: "Norman’s Duel: Apes",
                    whiteListFormLink: process.env.VUE_APP_APES_WHITELIST_FORM_LINK
                },
                beforeEnter: (to, from) => {

                    if (process.env.VUE_APP_APES_SOLD_OUT == 1) {
                        return {path: '/apes/sold-out'}
                    }

                    if (moment(process.env.VUE_APP_APES_EXPIRIED_DATE) > moment.utc()) {
                        return true
                    } else {
                        return {path: '/apes/wallet'}
                    }
                }
            },
            {
                path: 'wallet',
                component: ConnectWalletView,
                props: {
                    title: "Norman’s Duel: Apes",
                },
                beforeEnter: (to, from) => {
                    if (process.env.VUE_APP_APES_SOLD_OUT == 1) {
                        return {path: '/apes/sold-out'}
                    }

                    if (moment(process.env.VUE_APP_APES_EXPIRIED_DATE) <= moment.utc()) {
                        return true
                    } else {
                        return {path: '/apes'}
                    }
                }
            },
            {
                path: 'tokens',
                component: BuyView,
                props: {
                    price: process.env.VUE_APP_APES_PRICE,
                    title: "Norman’s Duel: Apes",
                },
                beforeEnter: (to, from) => {
                    if (process.env.VUE_APP_APES_SOLD_OUT == 1) {
                        return {path: '/apes/sold-out'}
                    }

                    if (
                        moment(process.env.VUE_APP_APES_EXPIRIED_DATE) <= moment.utc() &&
                        // eslint-disable-next-line no-undef
                        (typeof window.ethereum != 'undefined')
                    ) {
                        return true
                    } else {
                        return {path: '/apes/wallet'}
                    }
                }
            },
            {
                path: 'congrats',
                component: CongratsView,
                beforeEnter: (to, from) => {
                    if (process.env.VUE_APP_APES_SOLD_OUT == 1) {
                        return {path: '/apes/sold-out'}
                    }

                    if (
                        moment(process.env.VUE_APP_APES_EXPIRIED_DATE) <= moment.utc() &&
                        // eslint-disable-next-line no-undef
                        (typeof window.ethereum != 'undefined')
                    ) {
                        return true
                    } else {
                        return {path: '/apes/wallet'}
                    }
                }
            },
            {
                path: 'sold-out',
                component: SoldOutView,
                props: {
                    title: "Norman’s Duel: Apes",
                },
                beforeEnter: (to, from) => {
                    if (process.env.VUE_APP_APES_SOLD_OUT == 1) {
                        return true
                    } else {
                        return {path: '/apes/tokens'}
                    }
                }
            },
        ]
    },
    {
        path: '/ninja',
        name: 'ninja',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: NinjaView,
        children: [
            {
                path: '',
                component: CountdownView,
                props: {
                    expireDate: process.env.VUE_APP_NINJA_EXPIRIED_DATE,
                    title: "Norman’s Duel: Ninja",
                    whiteListFormLink: process.env.VUE_APP_NINJA_WHITELIST_FORM_LINK
                },
                beforeEnter: (to, from) => {
                    if (process.env.VUE_APP_NINJA_SOLD_OUT == 1) {
                        return {path: '/ninja/sold-out'}
                    }

                    if (moment(process.env.VUE_APP_NINJA_EXPIRIED_DATE) > moment.utc()) {
                        return true
                    } else {
                        return {path: '/ninja/wallet'}
                    }
                }
            },
            {
                path: 'wallet',
                component: ConnectWalletView,
                props: {
                    title: "Norman’s Duel: Ninja",
                },
                beforeEnter: (to, from) => {
                    if (process.env.VUE_APP_NINJA_SOLD_OUT == 1) {
                        return {path: '/ninja/sold-out'}
                    }

                    if (moment(process.env.VUE_APP_NINJA_EXPIRIED_DATE) <= moment.utc()) {
                        return true
                    } else {
                        return {path: '/ninja'}
                    }
                }
            },
            {
                path: 'tokens',
                component: BuyView,
                props: {
                    price: process.env.VUE_APP_NINJA_PRICE,
                    title: "Norman’s Duel: Ninja",
                },
                beforeEnter: (to, from) => {
                    if (process.env.VUE_APP_NINJA_SOLD_OUT == 1) {
                        return {path: '/ninja/sold-out'}
                    }

                    if (
                        moment(process.env.VUE_APP_NINJA_EXPIRIED_DATE) <= moment.utc() &&
                        // eslint-disable-next-line no-undef
                        (typeof window.ethereum != 'undefined')
                    ) {
                        return true
                    } else {
                        return {path: '/ninja/wallet'}
                    }
                }
            },
            {
                path: 'congrats',
                component: CongratsView,
                beforeEnter: (to, from) => {
                    if (process.env.VUE_APP_NINJA_SOLD_OUT == 1) {
                        return {path: '/ninja/sold-out'}
                    }

                    if (
                        moment(process.env.VUE_APP_NINJA_EXPIRIED_DATE) <= moment.utc() &&
                        // eslint-disable-next-line no-undef
                        (typeof window.ethereum != 'undefined')
                    ) {
                        return true
                    } else {
                        return {path: '/ninja/wallet'}
                    }
                }
            },
            {
                path: 'sold-out',
                component: SoldOutView,
                props: {
                    title: "Norman’s Duel: Ninja",
                },
                beforeEnter: (to, from) => {
                    if (process.env.VUE_APP_NINJA_SOLD_OUT == 1) {
                        return true
                    } else {
                        return {path: '/ninja/tokens'}
                    }
                }
            },
        ]
    },
    {
        path: '/cats',
        name: 'cats',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: CatsView,
        children: [
            {
                path: '',
                component: CountdownView,
                props: {
                    expireDate: process.env.VUE_APP_CATS_EXPIRIED_DATE,
                    title: "Norman’s Duel: Cats",
                    whiteListFormLink: process.env.VUE_APP_CATS_WHITELIST_FORM_LINK
                },
                beforeEnter: (to, from) => {
                    if (process.env.VUE_APP_CATS_SOLD_OUT == 1) {
                        return {path: '/cats/sold-out'}
                    }

                    if (moment(process.env.VUE_APP_CATS_EXPIRIED_DATE) > moment.utc()) {
                        return true
                    } else {
                        return {path: '/cats/wallet'}
                    }
                }
            },
            {
                path: 'wallet',
                component: ConnectWalletView,
                props: {
                    title: "Norman’s Duel: Cats",
                },
                beforeEnter: (to, from) => {
                    if (process.env.VUE_APP_CATS_SOLD_OUT == 1) {
                        return {path: '/cats/sold-out'}
                    }

                    if (moment(process.env.VUE_APP_CATS_EXPIRIED_DATE) <= moment.utc()) {
                        return true
                    } else {
                        return {path: '/cats'}
                    }
                }
            },
            {
                path: 'tokens',
                component: BuyView,
                props: {
                    price: process.env.VUE_APP_CATS_PRICE,
                    title: "Norman’s Duel: Cats",
                },
                beforeEnter: (to, from) => {
                    if (process.env.VUE_APP_CATS_SOLD_OUT == 1) {
                        return {path: '/cats/sold-out'}
                    }

                    if (
                        moment(process.env.VUE_APP_CATS_EXPIRIED_DATE) <= moment.utc() &&
                        // eslint-disable-next-line no-undef
                        (typeof window.ethereum != 'undefined')
                    ) {
                        return true
                    } else {
                        return {path: '/cats/wallet'}
                    }
                }
            },
            {
                path: 'congrats',
                component: CongratsView,
                beforeEnter: (to, from) => {
                    if (process.env.VUE_APP_CATS_SOLD_OUT == 1) {
                        return {path: '/cats/sold-out'}
                    }

                    if (
                        moment(process.env.VUE_APP_CATS_EXPIRIED_DATE) <= moment.utc() &&
                        // eslint-disable-next-line no-undef
                        (typeof window.ethereum != 'undefined')
                    ) {
                        return true
                    } else {
                        return {path: '/cats/wallet'}
                    }
                }
            },
            {
                path: 'sold-out',
                component: SoldOutView,
                props: {
                    title: "Norman’s Duel: Cats",
                },
                beforeEnter: (to, from) => {
                    if (process.env.VUE_APP_CATS_SOLD_OUT == 1) {
                        return true
                    } else {
                        return {path: '/cats/tokens'}
                    }
                }
            },
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    
})

export default router
