<template>
  <div class="jumbotron"></div>

  <div class="container-fluid">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'ApesView',
  mounted() {
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>
  .jumbotron {
    background-image: url(@/assets/img/monkey.png)
  }
</style>