<template>
  <div class="section section--roadmap" id="roadmap">
    <div class="container">
      <div class="title roadmap-title">
        ROADMAP
      </div>
      <div class="roadmap-row">
        <div class="roadmap-item">
          <div class="roadmap-item__img">
            <img src="@/assets/img/ninja/3.webp" alt="">
          </div>
          <div class="roadmap-item__date">February 15,2023</div>
          <div class="roadmap-item__info">
            Norman’s duel: Ninja <br/>1st relase
          </div>
        </div>
        <div class="roadmap-item">
          <div class="roadmap-item__img">
            <img src="@/assets/img/cats/12.webp" alt="">
          </div>
          <div class="roadmap-item__date">March 15,2023</div>
          <div class="roadmap-item__info">
            Norman’s duel: Cats <br/>2nd relase
          </div>
        </div>
        <div class="roadmap-item">
          <div class="roadmap-item__img">
            <img src="@/assets/img/aim.webp" alt="">
          </div>
          <div class="roadmap-item__date">April 2023</div>
          <div class="roadmap-item__info">
            Norman token
          </div>
        </div>
        <div class="roadmap-item">
          <div class="roadmap-item__img">
            <img src="@/assets/img/apes/9.webp" alt="">
          </div>
          <div class="roadmap-item__date">May 2023</div>
          <div class="roadmap-item__info">
            Norman’s duel: Apes <br/>3rd relase
          </div>
        </div>
        <div class="roadmap-item">
          <div class="roadmap-item__img">
            <img src="@/assets/img/vs.webp" alt="">
          </div>
          <div class="roadmap-item__date">June 2023</div>
          <div class="roadmap-item__info">
            Norman’s duel: <br/>(Play to earn)
          </div>
        </div>
      </div>
    </div>
  </div>
</template>