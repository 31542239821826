<template>
  <div class="pug pug-soldOut">
    <div class="pug-title" v-html="title"></div>
    <a href="#" class="pug-form__btn glitch-btn">
      <span class="glitch-text" data-glitch="SOLD OUT">SOLD OUT</span>
    </a>
    <div class="pug-wallet">
      Sale status
      <div>
        <span class="status status-gray">Closed</span>
      </div>
    </div>
    <div class="pug-soldOut__close">
      <a href="#">Close</a>
    </div>
  </div>
</template>


<script type="module">

export default {
  name: "SoldOutView",
  props: {
    title: String,
  },
}
</script>

<style scoped>

</style>