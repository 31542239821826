<template>
  <div class="section section--catalog" id="nftsouls">
    <div class="container">

      <div class="title catalog-title">NFT SOULS</div>

      <!--        ninja-->
      <div class="catalog">
        <div class="catalog-head">
          <div class="catalog-head__name">
            Norman's Duel: Ninja
          </div>
          <div class="catalog-head__sale">
            <span>Presale: February'23</span>
          </div>
        </div>
        <div class="catalog-slider" v-motion-fade-visible-once>
          <div class="swiper swiper--catalog">
            <div class="infiniteslide slider-left">
              <div class="swiper-slide" style="color: #00B240">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/ninja/1.webp" alt="">
                </div>
              </div>
              <div class="swiper-slide" style="color: #b30e1d">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/ninja/2.webp" alt="">
                </div>
              </div>
              <div class="swiper-slide" style="color: #0080BF">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/ninja/3.webp" alt="">
                </div>
              </div>
              <div class="swiper-slide" style="color: #00B1BB">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/ninja/4.webp" alt="">
                </div>
              </div>
              <div class="swiper-slide" style="color: #EA7900">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/ninja/5.webp" alt="">
                </div>
              </div>
              <div class="swiper-slide" style="color: #00B240">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/ninja/6.webp" alt="">
                </div>
              </div>
              <div class="swiper-slide" style="color: #01CEDF">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/ninja/7.webp" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="catalog-table">
          <table>
            <tr>
              <td>
                <div class="catalog-table__par">
                  Quantity:
                </div>
                <div class="catalog-table__data">
                  10.000 NFT
                </div>
              </td>
              <td>
                <div class="catalog-table__par">
                  Rarity:
                </div>
                <div class="catalog-table__data">
                  150+ hand-drawn attributes
                </div>
              </td>
              <td>
                <div class="catalog-table__par">
                  Mint Price:
                </div>
                <div class="catalog-table__data">
                  0.09 ETH per NFT
                </div>
              </td>
              <td>
                <div class="catalog-table__par">
                  Allocation:
                </div>
                <div class="catalog-table__data">
                  2 NFT per wallet address
                </div>
              </td>
            </tr>
          </table>
        </div>
        <router-link to="/wait" href="" class="btn btn-primary">Whitelist / Mint</router-link>
        <!-- <router-link to="/ninja" class="btn btn-primary">
                  <span class="glitch-text" data-glitch="Whitelist / Mint">Whitelist / Mint</span>
                </router-link> -->
      </div>

      <!--        cats-->
      <div class="catalog">
        <div class="catalog-head">
          <div class="catalog-head__name">
            Norman's Duel: Cats
          </div>
          <div class="catalog-head__sale">
            <span>Presale: March'23</span>
          </div>
        </div>
        <div class="catalog-slider" v-motion-fade-visible-once>
          <div class="swiper swiper--catalog">
            <div class="infiniteslide slider-right">
              <div class="swiper-slide" style="color: #002750">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/cats/8.webp" alt="">
                </div>
              </div>
              <div class="swiper-slide" style="color: #00B240">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/cats/1.webp" alt="">
                </div>
              </div>
              <div class="swiper-slide" style="color: #f50d1c">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/cats/2.webp" alt="">
                </div>
              </div>
              
              <div class="swiper-slide" style="color: #0090ad">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/cats/7.webp" alt="">
                </div>
              </div>
              <div class="swiper-slide" style="color: #EA7900">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/cats/3.webp" alt="">
                </div>
              </div>
              <div class="swiper-slide" style="color: #00575a">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/cats/4.webp" alt="">
                </div>
              </div>
              <div class="swiper-slide" style="color: #cecfcf">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/cats/6.webp" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="catalog-table">
          <table>
            <tr>
              <td>
                <div class="catalog-table__par">
                  Quantity:
                </div>
                <div class="catalog-table__data">
                  10.000 NFT
                </div>
              </td>
              <td>
                <div class="catalog-table__par">
                  Rarity:
                </div>
                <div class="catalog-table__data">
                  200+ hand-drawn attributes
                </div>
              </td>
              <td>
                <div class="catalog-table__par">
                  Mint Price:
                </div>
                <div class="catalog-table__data">
                  0.09 ETH per NFT
                </div>
              </td>
              <td>
                <div class="catalog-table__par">
                  Allocation:
                </div>
                <div class="catalog-table__data">
                  2 NFT per wallet address
                </div>
              </td>
            </tr>
          </table>
        </div>
        <router-link to="/wait" href="" class="btn btn-primary">Whitelist / Mint</router-link>
        <!-- <router-link to="/cats" class="btn btn-primary">
                    <span class="glitch-text" data-glitch="Whitelist / Mint">Whitelist / Mint</span>
                </router-link> -->
      </div>

      <!--        apes-->
      <div class="catalog">
        <div class="catalog-head">
          <div class="catalog-head__name">
            Norman's Duel: Apes
          </div>
          <div class="catalog-head__sale">
            <span>Presale: SOLD OUT</span>
            <span>Public sale: April'23</span>
          </div>

        </div>
        <div class="catalog-slider" v-motion-fade-visible-once>
          <div class="swiper swiper--catalog">
            <div class="infiniteslide slider-left">
              <div class="swiper-slide" style="color: #A803B6">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/apes/1.webp" alt="">
                </div>
              </div>
              <div class="swiper-slide" style="color: #00A1BB">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/apes/2.webp" alt="">
                </div>
              </div>
              <div class="swiper-slide" style="color: #531b1b">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/apes/13.webp" alt="">
                </div>
              </div>
              <div class="swiper-slide" style="color: #ce9201">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/apes/8.webp" alt="">
                </div>
              </div>
              <div class="swiper-slide" style="color: #005638">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/apes/6.webp" alt="">
                </div>
              </div>
              <div class="swiper-slide" style="color: #3D1F54">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/apes/3.webp" alt="">
                </div>
              </div>
              <div class="swiper-slide" style="color: #009CB5">
                <svg class="icon"><use xlink:href="#catalog-mask"></use></svg>
                <div class="swiper-slide__img">
                  <img src="@/assets/img/apes/7.webp" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="catalog-table">
          <table>
            <tr>
              <td>
                <div class="catalog-table__par">
                  Quantity:
                </div>
                <div class="catalog-table__data">
                  5.000 NFT
                </div>
              </td>
              <td>
                <div class="catalog-table__par">
                  Rarity:
                </div>
                <div class="catalog-table__data">
                  120+ hand-drawn attributes
                </div>
              </td>
              <td>
                <div class="catalog-table__par">
                  Mint Price:
                </div>
                <div class="catalog-table__data">
                  0.06 ETH per NFT
                </div>
              </td>
              <td>
                <div class="catalog-table__par">
                  Allocation:
                </div>
                <div class="catalog-table__data">
                  2 NFT per wallet address
                </div>
              </td>
            </tr>
          </table>
        </div>
        <router-link to="/wait" href="" class="btn btn-primary">Whitelist / Mint</router-link>
        <!-- <router-link to="/apes" class="btn btn-primary glitch-btn">
                    <span class="glitch-text" data-glitch="Whitelist / Mint">Whitelist / Mint</span>
                </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CatalogComponent',
  mounted() {
    console.log(123);
  }
}
</script>