<template>
  <!--
    add a class "js--alert-error" to the button on click on which the window is called
       -->
  <div class="modal-alert modal-alert--error">
    <button class="modal-alert__close js--alert-close" @click="close">x</button>
    <div class="modal-alert__title">
      Your address isn’t in the whitelist
    </div>
    <div class="modal-alert__phone">
      <img src="@/assets/img/phone-error.svg" alt="">
    </div>
    <div class="modal-alert__subtitle">We can’t find your address in the whitelist</div>
    <div class="modal-alert__field" v-html="wallet">

    </div>
    <div class="modal-alert__ok js--alert-close" @click="close">OK</div>
  </div>

  <!--
  add a class "js--alert-success" to the button on click on which the window is called
  -->
  <div class="modal-alert modal-alert--success">
    <button class="modal-alert__close js--alert-close" @click="close">x</button>
    <div class="modal-alert__title">
      Your address is in the whitelist
    </div>
    <div class="modal-alert__phone">
      <img src="@/assets/img/phone-success.svg" alt="">
    </div>
    <div class="modal-alert__subtitle">
      We found your address in the whitelist
    </div>
    <div class="modal-alert__icon">
      <img src="@/assets/img/icons/success.svg" alt="">
    </div>
    <div class="modal-alert__field" v-html="wallet">

    </div>
    <div class="modal-alert__ok js--alert-close" @click="close">OK</div>
  </div>

  <div class="overlay-alert"></div>
</template>

<script>

export default {
  name: "WalletPopupComponent",
  data() {
    return {
      wallet: ''
    }
  },
  methods: {
    openSuccess(wallet) {
      this.close()
      this.wallet = wallet
      $('.modal-alert--success').addClass('show');
      $('body').addClass('body-alert');
    },
    openError(wallet) {
      this.close()
      this.wallet = wallet
      $('.modal-alert--error').addClass('show');
      $('body').addClass('body-alert');
    },
    close() {
      $('.modal-alert').removeClass('show');
      $('body').removeClass('body-alert');
    }
  }
}
</script>

<style scoped>

</style>