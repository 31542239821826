<template>
  <div class="pug-timer">
    <div class="timer js-timer" :data-date="expireDate">
    </div>
  </div>
</template>

<script>
import moment from "moment"

export default {
  name: "TimerComponent",
  props: {
    expireDate: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.startTimer()
  },
  methods: {
    startTimer() {
      let el = document.querySelector(".js-timer")

      let dateFuture = moment(el.dataset.date);
      let dateNow = moment.utc();

      if (dateNow > dateFuture) {
        return
      }


      let seconds = (Math.floor((dateFuture - (dateNow)) / 1000));
      let minutes = Math.floor(seconds / 60);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);

      seconds = Math.floor(Math.abs((minutes * 60) - seconds));
      minutes = Math.floor(Math.abs((hours * 60) - minutes));

      if (hours < 24) {
        days = 0
      } else {
        hours = hours - (days * 24);
      }

      let dHours = hours.length === 1 ? '0' + hours : hours,
          dMinutes = minutes.length === 1 ? '0' + minutes : minutes,
          dSeconds = seconds.length === 1 ? '0' + seconds : seconds;

      el.innerHTML =
          '<div class="timer__num">'
          + days
          + '<span  class="timer__sub">days</span>'
          + '</div>'
          + '<div class="timer__num">'
          + dHours
          + '<span  class="timer__sub">hours</span>'
          + '</div>'
          + '<div class="timer__num">'
          + dMinutes
          + '<span  class="timer__sub">min</span>'
          + '</div>'
          + '<div class="timer__num">'
          + dSeconds
          + '<span  class="timer__sub">sec</span>'
          + '</div>';

      setTimeout(function step(el, days, hours, minutes, seconds) {

        seconds -= 1;
        if (seconds === -1) {
          seconds = 59;
          minutes = minutes - 1;
        }
        if (minutes === -1) {
          minutes = 59;
          hours = hours - 1;
        }
        if (hours === -1) {
          days = days - 1;
          hours = 23;
        }
        if (days === -1) {
          el.innerHTML = 'START!';
        } else {
          let displayHours = (hours + '').length === 1 ? '0' + hours : hours;
          let displayMinutes = (minutes + '').length === 1 ? '0' + minutes : minutes;
          let displaySeconds = (seconds + '').length === 1 ? '0' + seconds : seconds;
          el.innerHTML =
              '<div  class="timer__num">'
              + days
              + '<span  class="timer__sub">days</span>'
              + '</div>'
              + '<div  class="timer__num">'
              + displayHours
              + '<span  class="timer__sub">hours</span>'
              + '</div>'
              + '<div  class="timer__num">'
              + displayMinutes
              + '<span  class="timer__sub">min</span>'
              + '</div>'
              + '<div  class="timer__num">'
              + displaySeconds
              + '<span  class="timer__sub">sec</span>'
              + '</div>';
          setTimeout(step, 1000, el, days, hours, minutes, seconds);
        }
      }, 1000, el, days, hours, minutes, seconds);
    }
  }
}
</script>

<style scoped>

</style>