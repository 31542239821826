<template>
  <div class="section section--contact">
    <img src="@/assets/img/contact/bg.webp" class="contact-bg" alt="">
    <div class="container">
      <div class="contact-logo">
        <img src="@/assets/img/logo.svg" alt="">
      </div>
      <div class="title contact-title">
        JOIN OUR<br/>
        DISCORD & TWITTER
      </div>
      <div class="contact-btn">
        <a href="" target="_blank" class="btn btn-primary">twitter</a>
        <a href="" target="_blank" class="btn btn-primary">discord</a>
      </div>
    </div>
  </div>
</template>