<template>
  <div class="section section--today">
    <img src="@/assets/img/today/bg.webp" srcset="@/assets/img/today/bg@2x.webp" class="today-bg" alt="">
    <div class="text-center">
      <img src="@/assets/img/today/1.webp" srcset="@/assets/img/today/1@2x.webp" class="today-title" alt="">
    </div>
    <div class="container">
      <div class="today">
        <a href="" class="btn btn-primary">Challenger</a>
        <a href="" class="btn btn-primary">Accepter</a>
      </div>
    </div>
  </div>
</template>