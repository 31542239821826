<template>
  <div class="section section--wait wait--page" style="">
    <div class="container">
      <div class="title wait-title">
        CODE DUELLO
      </div>
      <div class="wait-img">
        <img src="@/assets/img/wait/1.webp" alt="">
      </div>
      <div class="wait-text">
        Wait and you'll get it soon
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaitView',
}
</script>
