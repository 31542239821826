<template>
  <div class="wrapper">
     <router-view />
  </div>

</template>

<style>
@import 'assets/css/main.css';
</style>

<script>
export default {

}

</script>