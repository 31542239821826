<template>
  <div class="pug">
    <div class="pug-title" v-html="title"></div>
    <div class="pug-title" v-html="expireDateStr"></div>
    <div class="pug-subtitle">Presale starts in ...</div>
    <timer-component :expireDate="expireDate"></timer-component>
    <pug-form-component :white-list-form-link="whiteListFormLink"></pug-form-component>
  </div>
</template>

<script>
import dateFormat from "dateformat";
import TimerComponent from "@/components/TimerComponent";
import PugFormComponent from "@/components/ntf_components/PugFormComponent";
import moment from "moment"

export default {
  name: "CountdownView",
  props: {
    title: String,
    whiteListFormLink: String,
    expireDate: String
  },
  computed: {
    expireDateStr() {
      return dateFormat(moment(this.expireDate), "dd.mm.yyyy 'at' h:MM tt")
    }
  },
  mounted() {
    setInterval(() => {
      let now = moment.utc()
      let expire = moment(this.expireDate)
      if (now > expire) {
          window.location.reload()
      }
    }, 1000)
  },
  components: {PugFormComponent, TimerComponent}
}
</script>

<style scoped>

</style>