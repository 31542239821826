<template>
  <div class="section--welcome">
    <img src="@/assets/img/welcome/bg.webp" srcset="@/assets/img/welcome/bg@2x.webp" class="welcome-bg welcome-bg--desk" alt="">
    <img src="@/assets/img/welcome/bg-mob.webp" srcset="@/assets/img/welcome/bg-mob@2x.webp" class="welcome-bg welcome-bg--mob" alt="">
    <div class="container">
      <header class="header">
        <div class="header-mobile">
          <a href="" class="logo">
            <img src="@/assets/img/logo.svg" alt="">
          </a>
          <div class="open-menu js--open-menu">
            <span></span><span></span><span></span>
          </div>
        </div>
        <div class="header-menu">
          <div class="header-col">
            <ul class="menu">
              <li><a href="#nftsouls">NFT SOULS</a></li>
              <li><a href="#duels">duels</a></li>
              <li><a href="#token">token</a></li>
            </ul>
          </div>
          <div class="header-col">
            <ul class="menu">
              <li><a href="#codeduello">CODE DUELLO</a></li>
              <li><a href="#market">MARKET</a></li>
              <li><a href="#roadmap">ROADMAP</a></li>
            </ul>
          </div>
          <router-link to="/" class="logo">
            <img src="@/assets/img/logo.svg" alt="">
          </router-link>
        </div>
      </header>

      <div class="welcome">
        <div class="welcome-title">
          <h1>
            All the
            <span class="h1-row">
                        <span>world’s</span>
                        <span>a duel</span>
                    </span>
          </h1>
          <img src="@/assets/img/welcome/man.webp" srcset="@/assets/img/welcome/man@2x.webp" class="welcome-man" alt="">
          <div class="welcome-title__note">
            and all the men and woman merely duelists
          </div>
        </div>
        <div class="clearfix">
          <div class="welcome-info">
            <p>And soon Norman Token <br/>will be more
              expensive than dollar and better than it.</p>
            <p>And there will be NFT Souls <br/>more expensive than gold.
            </p>
            <p>And in the Norman's Duel
              fortunes will be won.</p>
          </div>
        </div>
      </div>

    </div>
    <div class="scroll-down"></div>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent"
}
</script>

<style scoped>

</style>