<template>
  <div class="pug">
    <div class="pug-title" v-html="title"></div>
    <div class="pug-subtitle pug-subtitle__apes">
      Minting goes live now<br/>
      <span>Available only for whitelisted</span>
    </div>
    <div class="pug-form">
      <button class="pug-form__btn active glitch-btn pug-form__btn_last" @click="enableMetaMask">
        <span class="glitch-text" data-glitch="Connect your wallet">Connect your wallet</span>
      </button>
    </div>
    <wallet-popup-component ref="walletPopup"></wallet-popup-component>
  </div>
</template>

<script>
import WalletPopupComponent from "@/components/ntf_components/WalletPopupComponent";
import {mapActions} from "vuex";
export default {
  name: "CountdownView",
  props: {
    title: String,
    expireDate: String
  },
  data() {
    return {
      wallet: ''
    }
  },
  components: {WalletPopupComponent},
  methods: {
    async enableMetaMask() {
      // eslint-disable-next-line no-undef
      if (typeof window.ethereum == 'undefined') {
        alert("Please install MetaMask extension!")
        return
      }

      // eslint-disable-next-line no-undef
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
        .then(result => result)
        .catch(err => {
          if (err.code === -32002) {
            alert("Please enter Metamask extension and connect your wallet manually")
          }
        })

      if (!accounts) {
        return
      }
      // const accounts = ethereum.request({ method: 'eth_requestAccounts' })
      this.wallet = accounts[0]
      await this.validateWallet()
    },
    ...mapActions(['getWhitelist']),
    async validateWallet() {
      if (this.wallet.length > 0) {
        let whitelist = await this.getWhitelist()
        if (whitelist.includes(this.wallet.toLowerCase())) {
          this.$refs.walletPopup.openSuccess(this.wallet)
          setTimeout(() => this.$router.push('tokens'), 1500)
        } else {
          this.$refs.walletPopup.openError(this.wallet)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>